
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import NestedForms from "@/sg_copy/components/NestedForms.vue";
import GrantApp from "@/sg_copy/model/GrantApp";
import Response from "@/sg_copy/model/Response";
import ResponseSummaryCard from "@/sg_copy/components/ResponseSummaryCard.vue";
import {apiErrorUtil} from "@/sg_copy/utils/ApiErrorUtil";
import {GrantAppDetailDto, GrantAppTinyDto} from "@/sg_copy/swagger-generated";
import ErrorMessageCard from "@/sg_copy/components/ErrorMessageCard.vue";
import {namespace} from "vuex-class";
import CreateVariationModal from "@/sg_copy/components/CreateVariationModal.vue";

const mySubmissionStore = namespace("mySubmissionStore");

@Component({
  components: {
    CreateVariationModal,
    ResponseSummaryCard,
    ErrorMessageCard,
    NestedForms,
  },
})
export default class AppSubmissionExpandedCard extends Vue {
  @Prop() private app!: GrantApp;
  private appDetail: GrantAppDetailDto = null;
  private responses: Array<Response> = [];
  private error = false;

  @mySubmissionStore.Action
  public getApp;

  created() {
    if (this.app) {
      this.load();
    }
  }

  @Emit("open-response")
  public emitOpenResponse() {
    //emits
  }

  public load() {
    this.appDetail = null;
    this.responses.length = 0;
    this.error = false;

    this.getApp(this.app.id)
      .then(response => {
        this.appDetail = response.data;
        if (this.appDetail) {
          for (const response of this.appDetail.responses) {
            this.responses.push(new Response(response, this.app.user));
          }
        } else {
          this.error = true;
          apiErrorUtil.showApiError(
            this.app,
            "Unable to load application detail"
          );
        }
      })
      .catch(() => {
        this.error = true;
        apiErrorUtil.showApiError(
          this.app,
          "Unable to load application detail"
        );
      });
  }

  get hasNestedForms() {
    return this.appDetail?.childForms?.length > 0;
  }

  @Emit("show-variation-modal")
  public emitShowVariationModal() {
    //emits
  }

  @Emit("pass-show-variation-modal")
  public passShowVariationModal(app: GrantAppTinyDto) {
    //emits
  }
}
